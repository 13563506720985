"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const account_lookup_components_1 = require("merchant-portal/account-lookup-components");
const utils_1 = require("utils");
const reducer_1 = require("../../reducer");
const reducer_2 = require("../reducer");
const Routes_1 = require("../Routes");
function AccountLookUpResult({ accountsList, currentLocation, }) {
    // Context
    const ctx = (0, react_1.useContext)(Routes_1.LookupContext);
    // Hooks
    const history = (0, react_router_dom_1.useHistory)();
    if (!accountsList) {
        history.push(config_1.PATH.MERCHANT_PORTAL);
    }
    const accountListLength = accountsList?.accounts?.length ?? 0;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "account-lookup w-80", style: { margin: '0 auto', marginTop: '30px' }, children: [(0, jsx_runtime_1.jsxs)(components_1.H1, { children: [ctx.isTransact ? 'Transact' : 'Account', " Lookup Results"] }), !accountsList ? ((0, jsx_runtime_1.jsx)(components_1.Spinner, { centerPage: true })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [accountListLength === 0 && ((0, jsx_runtime_1.jsx)("div", { className: "account-lookup__empty-state", children: "No Results Found" })), accountListLength > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: "account-lookup__info", children: (0, jsx_runtime_1.jsxs)(components_1.P, { variant: "body-large", bold: true, children: ["We found ", accountListLength, " result", accountListLength > 1 && 's'] }) }), (0, jsx_runtime_1.jsx)("div", { className: "account-lookup-result", children: accountsList?.accounts?.map((account, index) => ((0, jsx_runtime_1.jsx)(account_lookup_components_1.AccountCard, { locationId: currentLocation?.locationId, account: account, onAccountSelection: () => history.push(`${config_1.ACCOUNT_DETAIL_URL}${account.alphaeonAccountNumber}`) }, index))) })] })), (0, jsx_runtime_1.jsx)("div", { className: "account-lookup__submit", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: `button-warn-outline`, onClick: () => {
                                if (ctx.isTransact) {
                                    history.push('/merchant/sales/lookup');
                                }
                                else {
                                    history.push(config_1.ACCOUNT_LOOKUP_URL);
                                }
                            }, children: "Search For A New Account" }) })] }))] }));
}
function mapStateToProps(state) {
    return {
        accountsList: reducer_2.selectors.accountsList(state),
        currentLocation: reducer_1.selectors.currentLocation(state),
    };
}
const mapDispatchToProps = {};
const mapApiAuthToProps = {
    searchForAccounts: apiActions.searchForAccounts,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(AccountLookUpResult);
