"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
function determinePrequalificationResponse(response, modalType) {
    const { applicationStatus, lenderNextStepUrl, applicationServiceProviderPriority, } = response;
    if (applicationStatus === 'prequalified') {
        return {
            pathname: config_1.WIZARD_URL,
            state: {
                step: 'PRE-QUALIFICATION',
            },
        };
    }
    else if (applicationStatus === 'prequalified_account_exists') {
        return {
            pathname: config_1.PRE_QUALIFICATION_ACCOUNT_EXISTS_URL,
        };
    }
    else if (applicationStatus === 'pending_approval') {
        // An assumption is currently made that pending_approval is returned
        // only for access loans. If additional product types associated with
        // a pending_approval status will be supported, this code this have to be
        // revisited to accommodate them.
        return {
            pathname: config_1.ACCESS_LOAN_URL,
            state: {
                lenderNextStepUrl,
                modalType,
                applicationStatus,
            },
        };
    }
    else {
        if (applicationServiceProviderPriority?.productType === 'installment') {
            return {
                pathname: config_1.PRE_QUALIFICATION_ACCEPT_DECLINED_URL,
            };
        }
        return {
            pathname: config_1.DECLINED_URL,
        };
    }
}
exports.default = determinePrequalificationResponse;
