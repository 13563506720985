"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const AnalyticsContext_1 = require("../context/AnalyticsContext");
const useRouteTracker = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const { analytics } = (0, react_1.useContext)(AnalyticsContext_1.AnalyticsContext);
    (0, react_1.useEffect)(() => {
        analytics?.track('page_view', { page: location.pathname });
    }, [location, analytics]);
};
exports.default = useRouteTracker;
