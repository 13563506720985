"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable no-console */
/* eslint-disable custom-rules/no-commented-out-code */
const react_1 = require("react");
function ApplicationEDS() {
    // Vars
    const htmlEl = document.querySelector('html');
    const config = { attributes: true, childList: true, subtree: true };
    // Observer Callback
    const obsCallback = (mutationList) => {
        for (const mutation of mutationList) {
            if (mutation.target.id === 'bread-payments-product-placement') {
                const btnStart = mutation.target?.children[0]?.children[0]?.children[0]?.children[0];
                if (btnStart) {
                    // Click the button
                    btnStart.click();
                    // Update Text
                    btnStart.innerText = 'Re-Open Application';
                }
            }
        }
    };
    // Effect
    (0, react_1.useEffect)(() => {
        const observer = new MutationObserver(obsCallback);
        observer.observe(htmlEl, config);
        () => {
            observer.disconnect();
        };
    }, []);
    // Effects
    (0, react_1.useEffect)(() => {
        if (window.BreadPayments) {
            const productPlacement = {
                financingType: 'card',
                locationType: 'landing',
                placementId: 'iframe-overlay-alphaeon',
                // locationType: 'product',
                // placementId: 'product-placement-alphaeon',
                domID: 'bread-payments-product-placement',
                order: { totalPrice: 500000 },
            };
            const setupConfig = {
                loyaltyID: '',
                storeNumber: 99996,
                existingCardHolder: false,
                env: 'STAGE',
                buyer: {
                    givenName: 'John',
                    familyName: 'Doe',
                    email: 'jafar.amjad@alphaeon.com',
                    phone: '123-456-7890',
                },
            };
            // Setup and Register
            window.BreadPayments.setup(setupConfig);
            window.BreadPayments.registerPlacements([productPlacement]);
            // Events
            window.BreadPayments.on('CARD:*', (cardResult) => {
                console.log('card res: ', cardResult);
            });
            // const iframe = document.getElementById('iframe') as HTMLIFrameElement
            // if (
            //   iframe &&
            //   iframe.contentWindow &&
            //   iframe.contentWindow.document.body
            // ) {
            //   const the_height = iframe.contentWindow.document.body.scrollHeight
            //   console.log('the_height: "', the_height)
            // }
        }
    }, [window.BreadPayments]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "container", children: [(0, jsx_runtime_1.jsx)("div", { id: "bread-payments-product-placement", className: "text-center" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {})] }));
}
exports.default = ApplicationEDS;
