"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("components");
const config_1 = require("config");
function LongLinkFetcher() {
    const [response, setResponse] = (0, react_1.useState)(null);
    const [error, setError] = (0, react_1.useState)('');
    const [loading, setLoading] = (0, react_1.useState)(true);
    const location = (0, react_router_dom_1.useLocation)();
    const id = location.pathname.split('/')[2];
    const createOtlToken = async (storeLinkCampaign, storeId) => {
        return fetch(`${config_1.REACT_APP_API_URL}/consumer_applications/v1/link/otl/generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                store_link_campaign: storeLinkCampaign,
                store_id: storeId,
            }),
        })
            .then((res) => {
            return res.json();
        })
            .then((data) => {
            return data.token;
        });
    };
    const handleSpecialResponses = async (response) => {
        if (response.long_link.includes('store_link_campaign')) {
            try {
                const url = new URL(response.long_link);
                const campaign = url.searchParams.get('store_link_campaign');
                const storeIdMatch = response.long_link.match(/store\/([^?]+)/);
                const storeId = storeIdMatch ? storeIdMatch[1] : '';
                if (campaign) {
                    const otlToken = await createOtlToken(campaign, storeId);
                    return appendOtlToLongLink(response.long_link, otlToken);
                }
                else {
                    return response.long_link;
                }
            }
            catch (e) {
                setError('Error extracting campaign data from URL:');
                return response.long_link;
            }
        }
        else {
            return response.long_link;
        }
    };
    const appendOtlToLongLink = (longLink, otl) => {
        try {
            const url = new URL(longLink);
            url.searchParams.append('otl', otl);
            return url.toString();
        }
        catch (e) {
            // If URL parsing fails, return the original URL with a simple parameter append
            if (longLink.includes('?')) {
                return `${longLink}&otl=${otl}`;
            }
            else {
                return `${longLink}?otl=${otl}`;
            }
        }
    };
    (0, react_1.useEffect)(() => {
        if (response && new Date(response.expires_at).getTime() > Date.now()) {
            handleSpecialResponses(response)
                .then((longLink) => {
                window.location.assign(longLink);
            })
                .catch((err) => {
                setError('Error handling special response:' + err);
                window.location.assign(response.long_link); // Fallback to original link
            });
        }
        else if (response) {
            setError('The link you tried to access is either invalid or expired.');
        }
    }, [response]);
    (0, react_1.useEffect)(() => {
        fetch(`${config_1.REACT_APP_API_URL}/consumer_applications/v1/link/fetch`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ short_link: id }),
        })
            .then((res) => {
            return res.json();
        })
            .then((data) => {
            setResponse(data);
        })
            .catch(() => {
            setError('The link you tried to access is either invalid or expired.');
        })
            .finally(() => {
            setLoading(false);
        });
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [loading && (0, jsx_runtime_1.jsx)(components_1.Spinner, {}), error && ((0, jsx_runtime_1.jsx)(components_1.CustomErrorPage, { title: "Invalid Link Error", description: `Oops there appears to be a problem. ${error}` }))] }));
}
exports.default = LongLinkFetcher;
