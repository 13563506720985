"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsContext = exports.AnalyticsProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const analytics_1 = require("analytics");
const react_1 = require("react");
const constants_1 = require("./constants");
// Context
const AnalyticsContext = (0, react_1.createContext)(constants_1.baseContext);
exports.AnalyticsContext = AnalyticsContext;
// Component
const AnalyticsProvider = ({ children, appName: initialAppName, }) => {
    // State
    const [appName, setAppName] = (0, react_1.useState)(initialAppName || null);
    const [config, setConfig] = (0, react_1.useState)(constants_1.baseConfig);
    const [analytics, setAnalytics] = (0, react_1.useState)(null);
    // Effect - First set appName
    (0, react_1.useEffect)(() => {
        if (appName !== null &&
            (!!config.plugins?.length || !!constants_1.PLUGINS[appName]?.length)) {
            constants_1.baseConfig.plugins = constants_1.PLUGINS[appName];
            setConfig({
                ...constants_1.baseConfig,
                app: appName,
            });
        }
    }, [appName]);
    // Effect - Second set config and analytics object
    (0, react_1.useEffect)(() => {
        if (config.app !== null) {
            const tmpAnalytics = (0, analytics_1.Analytics)(config);
            setAnalytics(tmpAnalytics);
        }
    }, [config]);
    return ((0, jsx_runtime_1.jsx)(AnalyticsContext.Provider, { value: { analytics, setAppName }, children: children }));
};
exports.AnalyticsProvider = AnalyticsProvider;
