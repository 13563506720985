"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const AnalyticsContext_1 = require("../context/AnalyticsContext");
const useButtonClickTracker = () => {
    const { analytics } = (0, react_1.useContext)(AnalyticsContext_1.AnalyticsContext);
    const location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(() => {
        const handleClick = (event) => {
            const target = event.target;
            if (target.tagName === 'BUTTON' && (target.id || target.innerText)) {
                analytics?.track('button_click', {
                    button_id: target.id || target.innerText,
                    page: location.pathname,
                });
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [analytics]);
};
exports.default = useButtonClickTracker;
